import {brandConfigMap, getBrandConfig} from "@/brand";
import store from "../store";
import {GameToBrandMap} from "@/brand/brandConfig";

export function setBrandConfig() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : getBrandConfigFromGameParameter();
    store.commit('setBrandConfig', brandConfig);
}

export function getBrandConfigFromGameParameter() {
    const urlParams = new URLSearchParams(window.location.search);
    const game = urlParams.get('game');
    if (GameToBrandMap.has(game)) return getBrandConfig(GameToBrandMap.get(game));
    return brandConfigMap.get('default');
}
