import store from "../store";
import DcbService from "../services/dcbService";
import UpstreamIntegrationService from "../services/upstreamIntegrationService";
import {triggerPostbackForSuccessfulSubscription} from "./utagDataPost";
import {handleDcbGameRedirects} from "@/functions/handleDcbGameRedirects";

export async function handleActionClick(user, next, msisdn) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    if (!action) window.location = `${store.state.brandConfig.gameUrl}`;
    else if (action === 'accept-tier2-v1' && store.state.brandConfig.revenuePartnerId === undefined) return await handleDcbAcceptButtonTier2Version1(user, next, msisdn);
    else if (action === 'accept-tier2-v1' && store.state.brandConfig.revenuePartnerId) return await handleUpstreamAcceptButtonTier2Version1(user, next);
    else return next({path: `/generic-error`}); //should never run !
}

async function handleDcbAcceptButtonTier2Version1(user, next, msisdn) {
    const userMsisdn = user?.msisdn ?? msisdn;
    await executeDoiTwoAndSubscribeUser(userMsisdn, next)
}

async function handleUpstreamAcceptButtonTier2Version1(user, next) {
    await UpstreamIntegrationService.doiTwo(user.msisdn)
    const subResponse = (await UpstreamIntegrationService.subscribeV2(user.msisdn, user.token, store.state.campaignId, store.state.googleClickId, store.state.clickId)).data.result;
    if (subResponse === true) {
        window.location = `https://fantasysoccer.vodacomfantasy.com/?fantasyToken=${user.token}`
    } else return next({path: `/generic-error`});
}

async function executeDoiTwoAndSubscribeUser(userMsisdn, next) {
    const serviceId = localStorage.getItem('serviceId') ?? store.state.serviceId ?? store.state.brandConfig.serviceId;
    await DcbService.doiTwo(userMsisdn, serviceId);
    const subscribeUserResponse = await DcbService.subscribeViaProductId(userMsisdn, parseInt(store.state.campaignId), serviceId, store.state.brandConfig.productId, store.state.googleClickId, store.state.clickId);
    await handleUserSubscribeResponse(userMsisdn, subscribeUserResponse, next);
}

async function handleUserSubscribeResponse(userMsisdn, subscribeUserResponse, next) {
    setUser(subscribeUserResponse);
    const getEncryptedMsisdnResponse = await DcbService.getEncryptedMsisdn(userMsisdn);
    if (subscribeUserResponse.result) {
        triggerPostbackForSuccessfulSubscription();
        const encryptedMsisdn = getEncryptedMsisdnResponse.data.encryptedMsisdn;
        return handleDcbGameRedirects(encryptedMsisdn);
    } else return next({path: `/generic-error`});
}

function setUser(user) {
    store.commit('setUser', user);
}
