import store from "../store";

export default function () {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignId = urlParams.get('campaignId') ?? urlParams.get('cid');
    const googleClickId = urlParams.get('gclid') ?? urlParams.get('tmt_data');
    const clickId = urlParams.get('clickid') ?? urlParams.get('clickId') ?? urlParams.get('click_id') ?? urlParams.get('ofreqid') ?? urlParams.get('transaction_id');
    const theme = urlParams.get('theme');

    setCampaignParametersInStore(campaignId, googleClickId, clickId, theme);

    return {
        campaignId: campaignId,
        googleClickId: googleClickId,
        clickId: clickId,
        theme: theme
    }
}

function setCampaignParametersInStore(campaignId, googleClickId, clickId, theme) {
    if (campaignId) store.commit('setCampaignId', campaignId);
    if (clickId) store.commit('setClickId', clickId);
    if (googleClickId) store.commit('setGoogleClickId', googleClickId);
    if (theme) store.commit('setTheme', theme);
}
