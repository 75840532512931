import {environmentConfig} from "../config/enviromentConfig";
import axios from "axios";

export default class UpstreamIntegrationService {
    static async doiTwo(msisdn) {
        try {
            return await axios.get(`${environmentConfig.vodacomIntegrationService}/doi-two`, {
                msisdn: msisdn,
                revenuePartnerId: 1,
                action: 'Subscription',
                channel: 'FantasyLeague-Web'
            })
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeV2(msisdn, sessionId, campaignId, googleClickId, clickId) {
        try {
            return await axios.post(`${environmentConfig.upstreamIntegrationService}/v2/subscription`, {
                msisdn: msisdn,
                revenuePartnerId: '1',
                action: 'Subscription',
                sessionId: sessionId,
                campaignId: campaignId,
                googleClickId,
                clickId
            })
        } catch (e) {
            return {authError: true};
        }
    }

    static async msisdnLogin(msisdn) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/msisdn-login`, {
            msisdn
        })
    }


    static async trackLogin() {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/track-login`, {
            msisdn: null,
            revenuePartnerId: 1,
            isFantasy: 1,
            isScorePredictor: 0,
            isWeb: 1
        })
    }

    static async getUserStatus(token) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/get-user-status`, {
            token
        })
    }

    static async requestOtp(msisdn) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/request-otp`, {
            msisdn: msisdn,
            revenuePartnerId: 1
        })
    }

    static async validateOtp(msisdn, otp) {
        const response = await axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/validate-otp`, {
            msisdn: msisdn,
            otp: otp,
        });
        return response?.data;
    }

    static async trackImpression(campaignId, acquisitionChannel = 'Organic-Web', revenuePartnerId = 2, onWifi = 1) {
        try {
            await axios.post(`${environmentConfig.upstreamIntegrationService}/marketing/track-impression`, {
                msisdn: null,
                campaignId: campaignId,
                acquisitionChannel: acquisitionChannel,
                revenuePartnerId: revenuePartnerId,
                onWifi: onWifi
            }, {
                headers: {}
            });
        } catch (error) {
        }
    }
}
