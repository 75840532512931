import {injectGoogleTagScriptCampaigns, injectScriptCampaigns} from "@/config/campaignConfig";
import {Brands} from "@/brand/brandConfig";

export default function (campaignId, brand) {
    if (injectScriptCampaigns.has(campaignId)) loadVodamediaScripts()
    else if (injectGoogleTagScriptCampaigns.has(campaignId)) loadGoogleTagScripts()
    if (brand === Brands.VodacomMasterStream || brand === Brands.VodacomEvinaMasterStream) loadFloodLightTagScript();
}

function loadGoogleTagScripts() {
    loadGlobalEventScript();
    loadViaMediaEventGoogleScript();
}

function loadVodamediaScripts() {
    loadEntryPointScript()
    loadTrackTargetScript();
    loadCartBoosterScript();
}

function loadEntryPointScript() {
    const entryPointScriptString = `window.tmt_offer_sid = '6kmnu8';
    (function () {
        var timestamp = +new Date;
        var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
        po.src = '//cdn.tmtarget.com/tracking/s/entrypoint_v2.min.js?t=' + timestamp;
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
    })();`;
    const entryPointScript = document.createElement('script');
    entryPointScript.type = "text/javascript";
    entryPointScript.text = entryPointScriptString;
    document.head.appendChild(entryPointScript)
}

function loadFloodLightTagScript() {
    const noScriptImageTag = document.createElement('img');
    noScriptImageTag.src = `https://ad.doubleclick.net/ddm/activity/src=14766482;type=invmedia;cat=maste0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=GDPR;gdpr_consent=GDPR_CONSENT_755;ord=${Math.random() * 10000000000000}`
    noScriptImageTag.width = 1;
    noScriptImageTag.height = 1;
    noScriptImageTag.alt = "";
    const noScript = document.createElement('noscript');
    noScript.appendChild(noScriptImageTag);

    document.body.appendChild(noScript)
}

function loadTrackTargetScript() {
    const trackTargetScript = document.createElement('script');
    trackTargetScript.src = "//t.trackmytarget.com/jp2b1z/6kmnu8";
    trackTargetScript.async = true;
    document.head.appendChild(trackTargetScript);
}

function loadCartBoosterScript() {
    const cartBoosterScript = document.createElement('script');
    cartBoosterScript.src = "//s.cartbooster.io/preload";
    cartBoosterScript.async = true;
    cartBoosterScript.type = "text/javascript";
    document.head.appendChild(cartBoosterScript);
}

function loadViaMediaEventGoogleScript() {
    const viaMediaScript = document.createElement('script');
    viaMediaScript.text = `gtag('event', 'conversion', {
    'allow_custom_scripts': true,
    'send_to': 'DC-14598969/invmedia/penro0+standard'
    });`;
    const noScript = createNoScriptElement();
    document.head.appendChild(viaMediaScript);
    document.head.appendChild(noScript);
}

function createNoScriptElement(){
    const noScriptImageTag = document.createElement('img');
    noScriptImageTag.src = "https://ad.doubleclick.net/ddm/activity/src=14598969;type=invmedia;cat=penro0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
    noScriptImageTag.width = 1;
    noScriptImageTag.height = 1;
    noScriptImageTag.alt = "";
    const noScript = document.createElement('noscript');
    noScript.appendChild(noScriptImageTag);
    return noScript;
}

function loadGlobalEventScript(){
    const globalEventScript = document.createElement('script');
    globalEventScript.async = true;
    globalEventScript.src = `https://www.googletagmanager.com/gtag/js?id=DC-14598969`;

    const dataLayerScript = document.createElement('script');
    dataLayerScript.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'DC-14598969');`;
    document.head.appendChild(globalEventScript);
    document.head.appendChild(dataLayerScript);
}
