<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container"
         :class="{ 'white-background': brandConfig.gameTag === 'master-stream' }">
      <LoadingOverlay v-if="this.isLoading"/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {setBrandConfig} from "./functions/setBrandConfig";
import addCustomVodamediaScripts from "./functions/addCustomVodamediaScripts";
import getCampaignParametersFromUrl from "./functions/getCampaignParametersFromUrl";
import injectHyveMzansiGamesScript from "@/functions/injectHyveMzansiGamesScript";
import LoadingOverlay from "@/views/components/LoadingOverlay";

export default {
  components: {LoadingOverlay},
  computed: {
    ...mapState(['brandConfig', 'isLoading'])
  },
  methods: {
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./brand/${this.brandConfig.brand}/assets/game-logo.png`)
    },
    setTabName() {
      document.title = this.brandConfig.gameTitle;
    },
    injectTealium() {
      (function (a, b, c, d) {
        a = 'https://tags.tiqcdn.com/utag/vodafone/za-quizinc-main/prod/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    }
  },
  beforeMount() {
    setBrandConfig();
    const {campaignId} = getCampaignParametersFromUrl();
    addCustomVodamediaScripts(campaignId, this.brandConfig.brand);
    this.setFavicon()
    this.setTabName();
    // if (this.brandConfig.productId) this.injectTealium();
    if (this.brandConfig.brand === 'vc-za-mg-encrypt') {
      setTimeout(() => {
        injectHyveMzansiGamesScript();
      }, 1000);
    }
  },
}
</script>

<style>
.vue-container {
  position: relative;
  background: #1c1c1c;
  background-size: cover;
  min-height: 100vh;
  color: #FFFFFF;
}

.white-background {
  background: #FFFFFF !important;
}
</style>
