export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomFantasyFootball: 'vc-za-ff',
    VodacomQuizInc: 'vc-za-qi',
    VodacomEvinaQuizInc: 'vc-za-qi-encrypt',
    VodacomStopClock: 'vc-za-stc',
    VodacomEvinaStopClock: 'vc-za-stc-encrypt',
    VodacomUnlockThree: 'vc-za-u3',
    VodacomEvinasUnlockThree: 'vc-za-u3-encrypt',
    VodacomHeadToHead: 'vc-za-h2h',
    VodacomEvinaHeadToHead: 'vc-za-h2h-encrypt',
    VodacomBeatTheJams: 'vc-za-btj',
    VodacomEvinaBeatTheJams: 'vc-za-btj-encrypt',
    VodacomMasterStream: 'vc-za-ms',
    VodacomEvinaMasterStream: 'vc-za-ms-encrypt',
    VodacomMzansiGames: 'vc-za-mg',
    VodacomEvinaMzansiGames: 'vc-za-mg-encrypt',
    VodacomCricketZone: 'vc-za-cz',
    VodacomMotorsportZone: 'vc-za-mz',
    VodacomRugbyZone: 'vc-za-rz',
};

export const GameToBrandMap = new Map();
GameToBrandMap.set('fantasy-football', Brands.VodacomFantasyFootball);
GameToBrandMap.set('quiz-inc', Brands.VodacomQuizInc);
GameToBrandMap.set('quiz-inc-encrypt', Brands.VodacomEvinaQuizInc);
GameToBrandMap.set('stop-the-clock', Brands.VodacomStopClock);
GameToBrandMap.set('stop-the-clock-encrypt', Brands.VodacomEvinaStopClock);
GameToBrandMap.set('unlock-3', Brands.VodacomUnlockThree);
GameToBrandMap.set('unlock-3-encrypt', Brands.VodacomEvinasUnlockThree);
GameToBrandMap.set('head-2-head', Brands.VodacomHeadToHead);
GameToBrandMap.set('head-2-head-encrypt', Brands.VodacomEvinaHeadToHead);
GameToBrandMap.set('beat-the-jams', Brands.VodacomBeatTheJams);
GameToBrandMap.set('beat-the-jams-encrypt', Brands.VodacomEvinaBeatTheJams);
GameToBrandMap.set('master-stream', Brands.VodacomMasterStream);
GameToBrandMap.set('master-stream-encrypt', Brands.VodacomEvinaMasterStream);
GameToBrandMap.set('vc-za-mg', Brands.VodacomMzansiGames);
GameToBrandMap.set('vc-za-mg-encrypt', Brands.VodacomEvinaMzansiGames);
GameToBrandMap.set('cricket-zone', Brands.VodacomCricketZone);
GameToBrandMap.set('motorsport-zone', Brands.VodacomMotorsportZone);
GameToBrandMap.set('rugby-zone', Brands.VodacomRugbyZone);

brandConfigObjectMap.set(Brands.VodacomFantasyFootball, {
    gameTitle: 'Fantasy Football',
    gameTag: 'fantasy-football',
    termsConditionsTitle: 'Fantasy Football',
    gameUrl: 'https://vodacomfantasy.com',
    termsConditionsUrl: 'https://termsandconditions.vodacomfantasy.com',
    multiTierService: false,
    ussdString: '*130*2586#',
    baseRoute: '/upstream/opt-in-confirmation',
    revenuePartnerId: 1,
    pricepoint: 'R3/day',
    rawPricePoint: '3.00',
    prizePool: 'R200k in airtime'
});

brandConfigObjectMap.set(Brands.VodacomQuizInc, {
    gameTitle: 'Quizinc',
    gameTag: 'quiz-inc',
    serviceId: 'vc-fanclash-quizinc-01',
    weeklyServiceId: 'vc-fanclash-vodacom-quizinc-weekly-01',
    termsConditionsTitle: 'QuizInc VIP',
    gameUrl: 'https://quizinc.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/quiz-inc',
    multiTierService: false,
    productId: 5,
    ussdString: '*117*77#',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R3/day',
    weeklyPricepoint: 'R20/week',
    rawPricePoint: '3.00',
    prizePool: 'R3 Million in airtime'
});

brandConfigObjectMap.set(Brands.VodacomEvinaQuizInc, {
    gameTitle: 'Quizinc',
    gameTag: 'quiz-inc',
    serviceId: 'vc-fanclash-quizinc-01',
    termsConditionsTitle: 'QuizInc VIP',
    gameUrl: 'https://quizinc.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/quiz-inc',
    multiTierService: false,
    productId: 5,
    ussdString: '*117*77#',
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: 'R3 Million in airtime',
    pricepoint: 'R3/day',
    rawPricePoint: '3.00',
    imageUrl: 'quiz-inc/quiz-inc-logo.png'
});

brandConfigObjectMap.set(Brands.VodacomStopClock, {
    gameTitle: 'Stop the Clock',
    gameTag: 'stop-the-clock',
    serviceId: 'vc-fanclash-quizinc-03',
    weeklyServiceId: 'vc-fanclash-vodacom-quizinc-stc-weekly-01',
    termsConditionsTitle: 'Stop the Clock',
    gameUrl: 'https://stopclock.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/stop-the-clock',
    multiTierService: false,
    productId: 3,
    ussdString: '*117*76#',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R3/day',
    weeklyPricepoint: 'R20/week',
    rawPricePoint: '3.00',
    prizePool: '500 daily airtime'
});

brandConfigObjectMap.set(Brands.VodacomEvinaStopClock, {
    gameTitle: 'Stop the Clock',
    gameTag: 'stop-the-clock',
    serviceId: 'vc-fanclash-quizinc-03',
    termsConditionsTitle: 'Stop the Clock',
    gameUrl: 'https://stopclock.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/stop-the-clock',
    multiTierService: false,
    productId: 3,
    ussdString: '*117*76#',
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: '500 daily airtime',
    pricepoint: 'R3/day',
    rawPricePoint: '3.00',
    imageUrl: 'stop-the-clock/game-logo.png'
});

brandConfigObjectMap.set(Brands.VodacomUnlockThree, {
    gameTitle: 'Unlock 3',
    gameTag: 'unlock-3',
    serviceId: 'vc-fanclash-quizinc-05',
    weeklyServiceId: 'vc-fanclash-vodacom-quizinc-uw-weekly-01',
    termsConditionsTitle: 'Unlock 3',
    gameUrl: 'https://unlock3.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/unlock-three',
    multiTierService: false,
    productId: 6,
    ussdString: '*117*75#',
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: '500 daily airtime',
    pricepoint: 'R3/day',
    weeklyPricepoint: 'R20/week',
    rawPricePoint: '3.00',
    imageUrl: 'stop-the-clock/game-logo.png'
});

brandConfigObjectMap.set(Brands.VodacomEvinasUnlockThree, {
    gameTitle: 'Unlock 3',
    gameTag: 'unlock-3',
    serviceId: 'vc-fanclash-quizinc-05',
    termsConditionsTitle: 'Unlock 3',
    gameUrl: 'https://unlock3.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/unlock-three',
    multiTierService: false,
    productId: 6,
    ussdString: '*117*75#',
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: '500 daily airtime',
    pricepoint: 'R3/day',
    rawPricePoint: '3.00',
    imageUrl: 'unlock-three/unlock3-logo.png'
});

brandConfigObjectMap.set(Brands.VodacomHeadToHead, {
    gameTitle: 'QuizInc Head 2 Head',
    gameTag: 'head-2-head',
    serviceId: 'vc-fanclash-quizinc-04',
    weeklyServiceId: 'vc-fanclash-vodacom-quizinc-tb-weekly-01',
    termsConditionsTitle: 'Quizinc Head 2 Head',
    gameUrl: 'https://quizhead2head.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/head-to-head',
    multiTierService: true,
    freeServiceId: 'vc-fanclash-quizinc-04-free',
    productId: 21,
    ussdString: '*117*74#',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R3/day',
    weeklyPricepoint: 'R20/week',
    rawPricePoint: '3.00',
    prizePool: '1,800Gb in data'
});

brandConfigObjectMap.set(Brands.VodacomEvinaHeadToHead, {
    gameTitle: 'QuizInc Head 2 Head',
    gameTag: 'head-2-head',
    serviceId: 'vc-fanclash-quizinc-04',
    termsConditionsTitle: 'Quizinc Head 2 Head',
    gameUrl: 'https://quizhead2head.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/head-to-head',
    multiTierService: true,
    freeServiceId: 'vc-fanclash-quizinc-04-free',
    productId: 21,
    ussdString: '*117*74#',
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: '1,800Gb in data',
    pricepoint: 'R3/day',
    rawPricePoint: '3.00',
    imageUrl: 'head-to-head/game-logo-vc-fanclash-quizinc-04.png'
});

brandConfigObjectMap.set(Brands.VodacomBeatTheJams, {
    gameTitle: 'Beat the Jams',
    gameTag: 'beat-the-jams',
    serviceId: 'vc-fanclash-beatthejams-01',
    weeklyServiceId: 'vc-fanclash-beatthejams-02',
    termsConditionsTitle: 'Beat the Jams VIP',
    gameUrl: 'https://beatthejams.co.za',
    termsConditionsUrl: 'https://www.beatthejams.co.za/terms-and-conditions',
    multiTierService: true,
    freeServiceId: 'vc-fanclash-musictrivia-00',
    productId: 18,
    ussdString: '*117*0#',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R3/day',
    weeklyPricepoint: 'R7/week',
    rawPricePoint: '3.00',
    prizePool: 'R1,2 Million'
});

brandConfigObjectMap.set(Brands.VodacomEvinaBeatTheJams, {
    gameTitle: 'Beat the Jams',
    gameTag: 'beat-the-jams',
    serviceId: 'vc-fanclash-beatthejams-01',
    termsConditionsTitle: 'Beat the Jams VIP',
    gameUrl: 'https://beatthejams.co.za',
    termsConditionsUrl: 'https://www.beatthejams.co.za/terms-and-conditions',
    multiTierService: true,
    freeServiceId: 'vc-fanclash-musictrivia-00',
    productId: 18,
    ussdString: '*117*0#',
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: 'R1,2 Million',
    pricepoint: 'R3/day',
    rawPricePoint: '3.00',
    imageUrl: 'beat-the-jams/btj-logo.png'
});

brandConfigObjectMap.set(Brands.VodacomMasterStream, {
    gameTitle: 'MasterStream',
    gameTag: 'master-stream',
    serviceId: 'vc-fanclash-masterstream-01',
    termsConditionsTitle: 'MasterStream',
    gameUrl: 'https://masterstream.co.za',
    termsConditionsUrl: 'https://masterstream.co.za/terms-conditions',
    multiTierService: false,
    productId: 29,
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: 'Join & get 125MB data to use for MasterStream today!',
    disclaimer: 'Standard data charges will apply when your 125MB data runs out.',
    pricepoint: 'R5/day',
    rawPricePoint: '5.00'
});

brandConfigObjectMap.set(Brands.VodacomEvinaMasterStream, {
    gameTitle: 'MasterStream',
    gameTag: 'master-stream',
    serviceId: 'vc-fanclash-masterstream-01',
    termsConditionsTitle: 'MasterStream',
    gameUrl: 'https://masterstream.co.za',
    termsConditionsUrl: 'https://masterstream.co.za/terms-conditions',
    multiTierService: false,
    productId: 29,
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: 'Join & get 125MB data to use for MasterStream today!',
    disclaimer: 'Standard data charges will apply when your 125MB data runs out.',
    pricepoint: 'R5/day',
    rawPricePoint: '5.00'
});

brandConfigObjectMap.set(Brands.VodacomMzansiGames, {
    gameTitle: 'Mzansi Games',
    gameTag: 'mzansi-games',
    serviceId: 'vc-fanclash-mzansigames-01',
    termsConditionsTitle: 'Mzansi Games',
    gameUrl: 'https://gameplay.mzansigames.club',
    termsConditionsUrl: 'https://gameplay.mzansigames.club/terms-conditions',
    multiTierService: false,
    productId: 23,
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: [
        'Play today & stand to win airtime prizes every hour!',
        'Play today & stand to win airtime prizes every hour!',
        'Play in eSports tournaments & stand a chance to WIN 30k Cash!',
        'Play skill-based games for R30 000 CASH monthly',
        'Stand a chance to win your share of R70 000 in airtime prizes!'
    ],
    pricepoint: 'R7/day',
    rawPricePoint: '7.00'
});

brandConfigObjectMap.set(Brands.VodacomEvinaMzansiGames, {
    gameTitle: 'Mzansi Games',
    gameTag: 'mzansi-games',
    serviceId: 'vc-fanclash-mzansigames-01',
    termsConditionsTitle: 'Mzansi Games',
    gameUrl: 'https://gameplay.mzansigames.club',
    termsConditionsUrl: 'https://gameplay.mzansigames.club/terms-conditions',
    multiTierService: false,
    productId: 23,
    baseRoute: '/dcb/evina/opt-in-confirmation',
    prizePool: [
        'Play today & stand to win airtime prizes every hour!',
        'Play today & stand to win airtime prizes every hour!',
        'Play in eSports tournaments & stand a chance to WIN 30k Cash!',
        'Play skill-based games for R30 000 CASH monthly',
        'Stand a chance to win your share of R70 000 in airtime prizes!'
    ],
    pricepoint: 'R7/day',
    rawPricePoint: '7.00'
});

brandConfigObjectMap.set(Brands.VodacomCricketZone, {
    gameTitle: 'Cricket Zone',
    gameTag: 'cricket-zone',
    serviceId: 'vc-fanclash-vodacom-fl-cricket-daily-01',
    termsConditionsTitle: 'Cricket Zone',
    gameUrl: 'https://cricketzone.co.za',
    termsConditionsUrl: 'https://cricketzone.co.za/terms-and-conditions',
    multiTierService: true,
    productId: 26,
    prizePool: 'Enjoy exclusive Cricket News, Fantasy, Trivia, Predictor & daily prizes!',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R5/day',
    rawPricePoint: '5.00'
});

brandConfigObjectMap.set(Brands.VodacomMotorsportZone, {
    gameTitle: 'Motorsport Zone',
    gameTag: 'motorsport-zone',
    serviceId: 'vc-fanclash-vodacom-fl-motorsport-daily-01',
    termsConditionsTitle: 'Motorsport Zone',
    gameUrl: 'https://motorsportzone.co.za',
    termsConditionsUrl: 'https://motorsportzone.co.za/terms-and-conditions',
    multiTierService: true,
    productId: 27,
    prizePool: 'Enjoy exclusive F1 News, Fantasy, Trivia, Predictor & daily prizes!',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R5/day',
    rawPricePoint: '5.00'
});

brandConfigObjectMap.set(Brands.VodacomRugbyZone, {
    gameTitle: 'Rugby Zone',
    gameTag: 'rugby-zone',
    serviceId: 'vc-fanclash-vodacom-fl-rugby-daily-01',
    termsConditionsTitle: 'Rugby Zone',
    gameUrl: 'https://rugby-zone.co.za',
    termsConditionsUrl: 'https://rugby-zone.co.za/terms-and-conditions',
    multiTierService: true,
    productId: 28,
    prizePool: 'Enjoy exclusive Rugby News, Fantasy, Trivia, Predictor & daily prizes!',
    baseRoute: '/dcb/opt-in-confirmation',
    pricepoint: 'R5/day',
    rawPricePoint: '5.00'
});
